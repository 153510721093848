//
// Quill
//

// Base
.ql-toolbar {
  font-family: $font-family-sans-serif;

  &.ql-snow {
    border: 1px solid var(--#{$prefix}border-color);
    @include border-top-radius($border-radius);

    .ql-picker,
    button {
      .ql-fill,
      .ql-stroke {
        stroke: var(--#{$prefix}gray-500);
      }

      .ql-fill {
        fill: var(--#{$prefix}gray-500);
      }

      &.ql-expanded,
      &.ql-active,
      &:focus,
      &:hover {
        .ql-fill,
        .ql-stroke {
          stroke: var(--#{$prefix}primary);
        }

        .ql-fill {
          fill: var(--#{$prefix}primary);
        }
      }
    }
  }
}

.ql-editor {
  color: var(--#{$prefix}input-color);

  &.ql-blank {
    &:before {
      left: auto !important;
      right: auto !important;
      color: var(--#{$prefix}text-muted) !important;
      font-style: normal !important;
    }
  }
}

.ql-container {
  &.ql-snow {
    background-color: var(--#{$prefix}input-bg);
    border: 1px solid var(--#{$prefix}gray-200);
    border-top: 0;
    @include border-bottom-radius($border-radius);
  }
}

.ql-snow {
  .ql-picker {
    .ql-picker-label {
      color: var(--#{$prefix}gray-500);

      &.ql-active,
      &:hover {
        color: var(--#{$prefix}primary);
      }
    }

    &.ql-expanded {
      outline: none !important;
      border-color: transparent !important;

      .ql-picker-label {
        border-color: transparent !important;
        color: var(--#{$prefix}primary);
        outline: none !important;

        &.ql-active,
        &:hover {
          color: var(--#{$prefix}primary);
        }
      }

      .ql-picker-options {
        border: 0;
        padding: 0.5rem 1rem;
        box-shadow: var(--#{$prefix}dropdown-box-shadow);
        background-color: var(--#{$prefix}body-bg);
        @include border-radius($border-radius);

        .ql-picker-item {
          color: var(--#{$prefix}gray-600);
          outline: none;

          &.ql-selected,
          &.ql-active,
          &:hover {
            color: var(--#{$prefix}primary);
          }
        }
      }
    }
  }

  .ql-tooltip {
    border: 0;
    padding: 0.5rem 1rem;
    box-shadow: var(--#{$prefix}dropdown-box-shadow);
    @include border-radius($border-radius);

    input[type='text'] {
      @include input-reset();
      border: 1px solid var(--#{$prefix}border-color);
      color: $input-plaintext-color;
      outline: none !important;
      @include border-radius($border-radius);

      &:active,
      &:focus {
        border-color: var(--#{$prefix}input-focus-border-color) !important;
      }
    }

    .ql-preview {
      color: var(--#{$prefix}gray-600);
    }

    .ql-action {
      transition: color 0.3s ease;
      color: var(--#{$prefix}gray-600);

      &:hover {
        transition: color 0.3s ease;
        color: var(--#{$prefix}primary);
      }
    }

    // Tooltip adjustment in modal
    .modal & {
      &.ql-editing {
        left: 20px !important;
      }
    }
  }

  .ql-editor {
    pre.ql-syntax {
      background-color: var(--#{$prefix}gray-900);
      color: var(--#{$prefix}text-muted);
      overflow: visible;
      @include border-radius($border-radius);
    }
  }
}

// Plain Style
.ql-quil.ql-quil-plain {
  .ql-toolbar {
    padding: 0;
    margin: 0;
    border: 0;

    &:after {
      display: none;
    }

    .ql-picker-label {
      padding-left: 0;
    }
  }

  .ql-container {
    border: 0;
  }

  .ql-editor {
    border: 0;
    padding: 0;
  }
}
