.summary-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-col {
  flex: 0 0 70%;
  padding: 10px;
}

.flex-col:after {
  content: "";
  flex: auto;
}

.gap_15 {
  gap: 1.5rem;
}

.height_35 {
  min-height: 3.5rem;
}
